import { Box, Container, Flex, Heading, Skeleton, SkeletonText, Text, useTheme } from '@chakra-ui/react'
import { Collection } from '@core/app/types/globalApiType'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { FC, useEffect, useMemo, useState } from 'react'
import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'

const baseClass = 'Advantages-section'

type Content = {
    [key: number | string]: string
}

export const Advantages: FC<{ id: number; languageId?: number }> = ({ id, languageId }) => {
    const { appTheme } = useAppTheme()
    const theme = useTheme()
    const { data: headerInfo, isLoading } = useGetHeaderInfoQuery({})
    // const [langId, setLangId] = useState<number>()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [content, setContent] = useState<Content>({})
    const [collection, setCollection] = useState<Collection>()
    const [scheme, setScheme] = useState<Content>({})

    useEffect(() => {
        setContent({})
        const schemeObject: Content = {}
        let collection: Collection = {}
        const landing = appTheme?.newSettings?.landing?.structure?.sections || {}
        const section = landing
            ? Object.values(appTheme?.newSettings?.landing?.structure?.sections || {}).find(
                  (section) => section.name === 'Advantages' && section.id === id
              )
            : null

        const fields = section ? section.fields : null
        schemeObject['primaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingPrimaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingPrimaryBackground}`
            : '#565656'
        schemeObject['secondaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingSecondaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingSecondaryBackground}`
            : '#393939'

        schemeObject['bg'] = appTheme?.newSettings?.landing?.colors?.LandingBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingBackground}`
            : theme.colors.bg[5]

        if (Array.isArray(fields)) {
            const updatedContent: Content = { ...content }
            fields.forEach((field) => {
                switch (field.alias) {
                    case 'Background color':
                        schemeObject['bg'] = field.value ? `#${field.value}` : schemeObject['bg']
                        break
                    case 'Text color':
                        schemeObject['textColor'] = `#${field.value}`
                        break
                    case 'Button color':
                        schemeObject['buttonColor'] = field.value ? `#${field.value}` : schemeObject['primaryColor']
                        break
                    case 'Heading':
                        schemeObject['heading'] = field.value
                        updatedContent[field.id] = field.value
                        break
                    case 'Advantages':
                        if (field.isCollection) {
                            collection = { ...appTheme?.newSettings?.landing?.structure?.collections[field.id] }
                        }
                        break
                }
            })

            setScheme(schemeObject)
            updateContent(updatedContent, collection)
        }
        setTimeout(() => {
            setLoaded(true)
        }, 1000)
    }, [appTheme])

    const updateContent = (content: Content, collection: Collection) => {
        const translations = appTheme?.newSettings.landing.structure.translations
        const newContent = { ...content }
        let newCollection = {}

        if (translations) {
            Object.keys(newContent || {}).forEach((contentId) => {
                Object.entries(translations || {}).forEach(([key, value]) => {
                    if (contentId === key) {
                        value.forEach((item) => {
                            if (item.languageId === languageId) {
                                newContent[contentId] = item.value
                            }
                        })
                    }
                })
            })
        }
        if (collection && translations) {
            Object.values(collection || {}).forEach((item) => {
                let newAdv = {}
                Object.entries(item || {}).forEach(([collectionKey, collectionValue]) => {
                    // @ts-ignore
                    let newValue = { ...collectionValue }
                    Object.entries(translations || {}).forEach(([key, value]) => {
                        if (collectionKey === key) {
                            value.forEach((item) => {
                                if (item.languageId === languageId) {
                                    newValue.value = item.value
                                }
                            })
                        }
                    })
                    newAdv = { ...newAdv, [collectionKey]: newValue }
                })
                newCollection = { ...newCollection, [Object.keys(newAdv)[0]]: newAdv }
            })
        } else {
            newCollection = { ...collection }
        }

        setCollection(newCollection)
        setContent(newContent)
    }

    const figureRotation = (index: number) => {
        switch (index) {
            case 0:
                return ''
            case 1:
                return 'rotate(45deg)'
            case 2:
                return 'rotate(90deg)'
            case 3:
                return 'rotate(135deg)'
            case 4:
                return 'rotate(180deg)'
            case 5:
                return 'rotate(225deg)'
            case 6:
                return 'rotate(270deg)'
            default:
                return ''
        }
    }

    // useMemo(() => {
    //     if (headerInfo?.locale?.current) {
    //         setLangId(Object.values(headerInfo.locale.current)[0].id)
    //     }
    // }, [headerInfo])

    return (
        <Box className={baseClass} as={'section'} py={{ base: '80px', xl: 30 }} bg={scheme.bg}>
            <Container
                maxW={{
                    base: 'container.landingSm',
                    sm: 'container.sm',
                    md: 'container.md',
                    lg: 'container.lg',
                    xl: 'container.xl',
                }}
            >
                {Object.values(content)[0] ? (
                    <Heading mb={12} size={'xl'} color={scheme.textColor}>
                        {Object.values(content)[0]}
                    </Heading>
                ) : (
                    <Skeleton mb={12} speed={0} w={'347px'} h={'36px'} borderRadius={'4px'} bg={'#D3D3D3'}></Skeleton>
                )}
                {collection && Object.values(collection || {}).length > 0 ? (
                    <Flex gap={12} flexWrap={'wrap'}>
                        {Object.values(collection || {}).map((item, index) => (
                            <Box w={'368px'} key={index}>
                                <Box
                                    w={'48px'}
                                    h={'48px'}
                                    position={'relative'}
                                    mb={6}
                                    transform={figureRotation(index)}
                                >
                                    <Box
                                        position={'absolute'}
                                        w={'16px'}
                                        h={'16px'}
                                        left={'3px'}
                                        top={'-2px'}
                                        bg={scheme.primaryColor}
                                        borderRadius={'full'}
                                        zIndex={1}
                                    />
                                    <Box
                                        position={'absolute'}
                                        w={'48px'}
                                        h={'48px'}
                                        overflow={'hidden'}
                                        borderRadius={'full'}
                                    >
                                        <Box
                                            position={'absolute'}
                                            w={'48px'}
                                            h={'48px'}
                                            left={0}
                                            top={0}
                                            bg={scheme.secondaryColor}
                                            borderRadius={'full'}
                                        />
                                        <Box
                                            position={'absolute'}
                                            w={'48px'}
                                            h={'48px'}
                                            left={'12px'}
                                            top={'18px'}
                                            bg={scheme.primaryColor}
                                            borderRadius={'full'}
                                            zIndex={2}
                                        />
                                    </Box>
                                </Box>
                                {(Object.values(item)[0] as { value: string }).value ? (
                                    <Heading color={scheme.textColor} size={'md'}>
                                        {(Object.values(item)[0] as { value: string }).value}
                                    </Heading>
                                ) : (
                                    <Skeleton
                                        speed={0}
                                        w={'265px'}
                                        h={'24px'}
                                        borderRadius={'4px'}
                                        bg={'#D3D3D3'}
                                    ></Skeleton>
                                )}
                                {(Object.values(item)[1] as { value: string }).value ? (
                                    <Text
                                        fontSize={'sm'}
                                        mt={3}
                                        color={scheme.textColor}
                                        lineHeight={6}
                                        sx={{
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {(Object.values(item)[1] as { value: string }).value}
                                    </Text>
                                ) : (
                                    <SkeletonText
                                        mt={3}
                                        speed={0}
                                        w={{ base: '280px', xl: '368px' }}
                                        startColor={'#E6E6E6'}
                                        noOfLines={4}
                                        spacing={'4'}
                                        skeletonHeight={'2'}
                                    />
                                )}
                            </Box>
                        ))}
                    </Flex>
                ) : (
                    <Flex gap={12} flexWrap={'wrap'}>
                        {Array.from(Array(6).keys()).map((_, index) => (
                            <Box w={'368px'} key={index}>
                                <Box
                                    w={'48px'}
                                    h={'48px'}
                                    position={'relative'}
                                    mb={6}
                                    transform={figureRotation(index)}
                                >
                                    <Box
                                        position={'absolute'}
                                        w={'16px'}
                                        h={'16px'}
                                        left={'3px'}
                                        top={'-2px'}
                                        bg={scheme.primaryColor}
                                        borderRadius={'full'}
                                        zIndex={1}
                                    />
                                    <Box
                                        position={'absolute'}
                                        w={'48px'}
                                        h={'48px'}
                                        overflow={'hidden'}
                                        borderRadius={'full'}
                                    >
                                        <Box
                                            position={'absolute'}
                                            w={'48px'}
                                            h={'48px'}
                                            left={0}
                                            top={0}
                                            bg={scheme.secondaryColor}
                                            borderRadius={'full'}
                                        />
                                        <Box
                                            position={'absolute'}
                                            w={'48px'}
                                            h={'48px'}
                                            left={'12px'}
                                            top={'18px'}
                                            bg={scheme.primaryColor}
                                            borderRadius={'full'}
                                            zIndex={2}
                                        />
                                    </Box>
                                </Box>
                                <Skeleton
                                    speed={0}
                                    w={'265px'}
                                    h={'24px'}
                                    borderRadius={'4px'}
                                    bg={'#D3D3D3'}
                                ></Skeleton>
                                <SkeletonText
                                    mt={6}
                                    speed={0}
                                    w={'368px'}
                                    startColor={'#E6E6E6'}
                                    noOfLines={3}
                                    spacing={3}
                                    skeletonHeight={4}
                                />
                            </Box>
                        ))}
                    </Flex>
                )}
            </Container>
        </Box>
    )
}
